// Copyright @2021 yucwang

import { Link } from "gatsby"
import React from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import { coverImage, 
         item, 
         title } from "./publication_list_item.module.css"

class PublicationListItem extends React.Component {
  render() {
    return (
      <div className={ item }>
        <div className={ coverImage }>
          <GatsbyImage image={ getImage(this.props.coverImage) } />
        </div>
        <div>
          <div className={ title }>
            <Link to={ this.props.linkUrl }><b>{ this.props.title }</b></Link>
          </div>
          <div class="authors">
            { this.props.authors }
          </div>
          <div class="conference">
            { this.props.conference }
          </div>
        </div>
      </div>
    )
  }
}

export default PublicationListItem
