// Copyright @yucwang 2021

import { graphql, Link, PageProps } from "gatsby"
import React from "react"

import Layout from "../components/layout"
import PublicationListItem from "../components/publications/publication_list_item"

import { list } from "../components/publications/publications.module.css"

const PublicationsPage: React.FC<PageProps> = (  {
  data: {
    allMdx: { edges },
  },
}) => {
  const posts = edges.map(edge => {
    // A temporal fix
    const linkUrl = (edge.node.frontmatter.linkUrl == null) ? 
                    '/' + edge.node.slug : 
                    edge.node.frontmatter.linkUrl
    return (
      <PublicationListItem 
        coverImage={ edge.node.frontmatter.coverImage }
        authors={ edge.node.frontmatter.authors }
        conference={ edge.node.frontmatter.conference }
        title={ edge.node.frontmatter.title }
        linkUrl={ linkUrl }
      >
      </PublicationListItem>
    )
  })

  return (
    <Layout>
      <h2> Publications </h2>
      <div className={ list }>
        { posts }
      </div>
    </Layout>
  )
}

export default PublicationsPage

export const pageQuery = graphql`
  query {
    allMdx(filter: {fileAbsolutePath: {regex: "/posts/publications/"}},
    sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          slug
          excerpt(pruneLength: 250)
          frontmatter {
            title
            authors
            conference
            date(formatString: "YYYY-MM-DD")
            coverImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
            linkUrl
          }
        }
      }
    }
  }
`
